import React from 'react';
import Layout from '../components/layout';
import '../components/about-page/about-page.scss';
import FS from '../components/about-page/fs/fs';
import History from '../components/about-page/history/history';
import Gallery from '../components/about-page/gallery/gallery';
import IgSubscribe from '../components/ig-subscribe/ig-subscribe';

const pageName = 'about-page';

const AboutPage = () => (
  <Layout pageName={pageName} containerClass="">
    <div className="container about-page__container">
      <FS />
      <History />
    </div>
    <Gallery />
    <div className="container about-page__container">
      <IgSubscribe className="about-page__ig-subscribe" />
    </div>
  </Layout>
);

export default AboutPage;
