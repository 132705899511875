import React from 'react';
import PropTypes from 'prop-types';

const GalleryCommon = (props) => {
  const { myRef, children, ...other } = props;

  // todo alex чекнуть как можно обойти ошибку, которую выдает eslint
  return (
    <div className="about-page__gallery">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div className="about-page__gallery-wrap" {...other} ref={myRef}>
        <div className="about-page__gallery-slider">{children}</div>
      </div>
    </div>
  );
};

// todo alex проверить тип myRef. Нет ли ошибки из-за этого типа
// todo alex разделить комп от мобилки вообще. То есть отрефакторить галерею
GalleryCommon.propTypes = {
  myRef: PropTypes.shape({ current: PropTypes.node }),
  children: PropTypes.node,
};

GalleryCommon.defaultProps = {
  myRef: React.createRef(),
  children: null,
};

export default GalleryCommon;
