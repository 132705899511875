import React from 'react';
import ImageWrap from './image-wrap';
import GalleryCommon from './gallery-common';

const GalleryMobile = () => (
  <GalleryCommon>
    <div className="about-page__gallery-slide">
      <ImageWrap name="" />
    </div>
    <div className="about-page__gallery-slide">
      <ImageWrap name="" />
    </div>
    <div className="about-page__gallery-slide">
      <ImageWrap name="" />
    </div>
    <div className="about-page__gallery-slide">
      <ImageWrap name="" />
    </div>
    <div className="about-page__gallery-slide">
      <ImageWrap name="" />
    </div>
  </GalleryCommon>
);

export default GalleryMobile;
