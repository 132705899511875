import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import Image from './image';
import LinkButton from '../buttons/link-button/external';
import commonSiteData from '../../__mocks__/commonSiteData';
import './ig-subscribe.scss';

const IgSubscribe = (props) => {
  const { className } = props;

  const ROOT_CLASS = 'ig-subscribe';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  // todo alex обернуть ссылку в hoc

  return (
    <div className={rootClass}>
      <div className="ig-subscribe__title page__title page__title_h3 center">
        Подписывайся на наш Instagram!
        <br />
        Там все свежие новости о скидках, услугах и фото красивых собачек
      </div>
      <div className="ig-subscribe__card">
        <div className="ig-subscribe__card-inner">
          <a
            href={commonSiteData.socials.ig}
            target="_blank"
            rel="noopener noreferrer"
            className="ig-subscribe__logo-wrap"
          >
            <Image className="ig-subscribe__logo" />
          </a>
          <LinkButton
            href={commonSiteData.socials.ig}
            target="_blank"
            rel="noopener noreferrer"
            outline
            theme="black"
            className="ig-subscribe__button"
            textClass="ig-subscribe__button-text"
          >
            Подписаться
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

IgSubscribe.propTypes = {
  className: PropTypes.string,
};

IgSubscribe.defaultProps = {
  className: '',
};

export default IgSubscribe;
