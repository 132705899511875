import React from 'react';
import './history.scss';

const History = () => (
  <div className="about-page__history">
    <div className="about-page__history-article">
      <div className="about-page__history-title page__title page__title_h3">
        Мне очень повезло, потому что Мария сильно меня любит и заботится обо мне. Забота проявляется в виде регулярных
        и полезных для моего здоровья и внешнего вида процедурах, которые могут сделать только в хороших груминг
        салонах.
      </div>

      <p className="about-page__history-text">
        За всю мою жизнь я побывала в разных груминг салонах, потому что моя заботливая хозяйка не останавливала поиски
        такого места, где качество услуг и уровень заботы обо мне были бы на наивысшем уровне. Где-то меня очень долго
        стригли, где-то немного обижали, а где-то даже могли случайно порезать. А Мария переживала, что иногда сервис
        был не на высоком уровне.
      </p>
    </div>

    <div className="about-page__history-article">

      <div className="about-page__history-title page__title page__title_h3">
        В один момент мы поняли, что самое современное в плане сервиса, стильное для хозяев и уютное для питомцев место
        мы можем создать сами.
      </div>

      <p className="about-page__history-text">
        Моя хозяйка хотела создать такой салон, услугами которого активному и вечно занятому человеку, было бы
        пользоваться максимально удобно и просто.
      </p>

      <p className="about-page__history-text">
        Именно поэтому в приоритете с самого начала стояли современные технологии в виде наличия собственного личного
        кабинета для каждого клиента, он-лайн записи в любое удобное время, автоматического напоминания о времени
        записи, возможности приобрести подарочные сертификаты он-лайн, зоо-такси и много другое.
      </p>
    </div>

    <div className="about-page__history-article">

      <div className="about-page__history-title page__title page__title_h3">
        Мы вместе хотим сделать мир красивее и лучше. TEZI Grooming — это искренняя любовь к вашему питомцу. Будем рады
        видеть вас в нашем салоне, чтобы поделиться нашей любовью!
      </div>

    </div>

  </div>
);

export default History;
