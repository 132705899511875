import React from 'react';
import ImageWrap from './image-wrap';
import GalleryCommon from './gallery-common';

const GalleryDesktop = () => {
  const myRef = React.createRef();

  const handleMouseLeave = () => {
    document.body.classList.remove('body_ovh');
  };

  let lastScrollLeft = 0;
  const handleWheel = (e) => {
    myRef.current.scrollLeft += e.deltaY / 5;
    const curScrollLeft = myRef.current.scrollLeft;

    if (lastScrollLeft === curScrollLeft) {
      document.body.classList.remove('body_ovh');
    } else {
      document.body.classList.add('body_ovh');
    }
    lastScrollLeft = curScrollLeft;
  };

  return (
    <GalleryCommon onMouseLeave={handleMouseLeave} onWheel={handleWheel} myRef={myRef}>
      <div className="about-page__gallery-slide">
        <ImageWrap name="" />
      </div>
      <div className="about-page__gallery-slide">
        <ImageWrap name="" />
        <ImageWrap name="" />
      </div>
      <div className="about-page__gallery-slide">
        <ImageWrap name="" />
        <ImageWrap name="" />
      </div>
      <div className="about-page__gallery-slide">
        <ImageWrap name="" />
        <ImageWrap name="" />
      </div>
      <div className="about-page__gallery-slide">
        <ImageWrap name="" />
        <ImageWrap name="" />
      </div>
    </GalleryCommon>
  );
};

export default GalleryDesktop;
