import React from 'react';
import PropTypes from 'prop-types';
import BaseButton from '../base-button/base-button';

const LinkButton = (props) => {
  const { href, target, rel } = props;

  // eslint-disable-next-line react/jsx-props-no-spreading,max-len
  const WrappedLink = (childProps) => <a href={href} target={target} rel={rel} {...childProps}>{childProps.children}</a>;

  return BaseButton(WrappedLink, props);
};

LinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  rel: PropTypes.string,
};

LinkButton.defaultProps = {
  target: '_self',
  rel: 'nofollow noreferrer',
};

export default LinkButton;
