import React from 'react';
import './gallery.scss';
import GalleryMobile from './gallery-mobile';
import GalleryDesktop from './gallery-desktop';
import useWindowSize from '../../../../libs/useWindowSize';

const Gallery = () => {
  const [width] = useWindowSize();
  const isDesktop = width >= 1024;

  // todo alex scroll right if cursor in slider and scrollig down
  //  https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
  //  https://codepen.io/Baromaro/pen/yLLMWMM
  //  https://codesandbox.io/s/github/Andarist/use-smooth-scroll/tree/master/examples/simple
  //  onWheel = {(e) => this.wheel(e)}

  return isDesktop ? <GalleryDesktop /> : <GalleryMobile />;
};

export default Gallery;
