import React from 'react';
import './fs.scss';
import Image from './image';

const FS = () => (
  <div className="about-page__fs">
    <div className="about-page__fs-body">
      <h1 className="about-page__fs-main-title">О нас</h1>
      <h2 className="about-page__fs-title page__title page__title_h1">
        Привет,
        <span>меня зовут Tezi!</span>
      </h2>
      <div className="about-page__text page__title page__title_h2">
        Я симпатичная, породистая и чистокровная мальтийская болонка. Мою хозяйку зовут Мария, и у нас любовь с первого
        взгляда! Мария ждала моего появления с самого детства, а я уже на протяжении 8 лет как можно быстрее бегу
        встречать ее с работы. Она классная, правда!
      </div>
    </div>

    <div className="about-page__fs-img-wrap">
      <Image className="about-page__fs-img" />
    </div>
  </div>
);

export default FS;
